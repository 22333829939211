import React, { useState } from 'react'
import { FaSearch, FaPlus, FaMinus } from 'react-icons/fa'

import SEO from '../components/seo'
import Layout from '../components/layout'

const FAQS = [
  {
    id: 1,
    q: 'Come controllo lo stato della mia spedizione (tracking)?',
    a:
      "È fondamentale avere il numero di spedizione. Nel caso in cui tu non ne sia in possesso, devi chiederlo al mittente. Per le spedizioni nazionali è composto da 1/2 lettere e 8/9 cifre, per quelle internazionali è composto da 11/12 numeri senza lettere. Quindi, basta inserirlo nell'apposito spazio ricerca spedizioni.",
  },
  {
    id: 2,
    q:
      'Lo stato della spedizione che trovo su www.mediterraneaexpress.com è quello più aggiornato?',
    a:
      "Sì, la sezione ricerca spedizione fornisce le informazioni più aggiornate sullo stato della spedizione. Le informazioni sul percorso vengono registrate ogni qualvolta un'etichetta viene letta dal sistema di consegna GLS. Se la spedizione attraversa l'intero paese o è destinata all'estero, è possibile che tra gli aggiornamenti trascorra qualche giorno",
  },
  {
    id: 3,
    q: 'È possibile variare indirizzo di consegna?',
    a:
      "No. L'indirizzo di consegna può essere variato soltanto facendone richiesta al mittente.",
  },
  {
    id: 4,
    q: 'La mia spedizione è in consegna oggi. A che ora arriverà il corriere?',
    a:
      "Le spedizioni standard vengono consegnate durante l'arco della giornata, indicativamente tra le ore 8.30 e le 18:30. La consegna su specifiche esigenze del destinatario avviene solo nel caso in cui il mittente abbia abbinato alla spedizione uno dei servizi accessori disponibili.",
  },
  {
    id: 5,
    q: 'Cosa significa la dicitura "SPEDIZIONE NON CONSEGNATA"?',
    a:
      'Per motivi logistici/distributivi la consegna avverrà il giorno lavorativo successivo, esclusi sabato, domenica e festivi.',
  },
  {
    id: 6,
    q: 'Cosa significa la dicitura "AFFIDATA AL CORRISPONDENTE"?',
    a:
      "In alcuni casi, come ad esempio le piccole isole o le zone remote, la spedizione viene affidata ad un partner locale che si occupa del trasporto nell'ultimo tratto fino alla consegna.",
  },
  {
    id: 7,
    q: 'Cosa significa la dicitura "ATTESA ALLA SEDE DI DESTINO"?',
    a:
      'La spedizione non è ancora arrivata, è in transito. È necessario attendere un aggiornamento il giorno successivo.',
  },
  {
    id: 8,
    q: 'Come si  richiede un ritiro?',
    a:
      "Per prenotare un ritiro di una spedizione presso il proprio domicilio basta utilizzare la funzione prenota il ritiro. Le richieste di ritiro devono essere inviate entro le ore 13.00 per il pomeriggio del giorno stesso. Le richieste inserite dopo le 13.00 verranno gestite il giorno successivo esclusi sabato e giorni festivi. I clienti abbonati devono effettuare l'accesso con dati forniti dalla sede GLS dove è stato stipulato il contratto. I clienti occasionali devono registrarsi per accedere al servizio. Porto franco significa che la spedizione la paga il mittente. Porto assegnato indica che il costo della spedizione è a carico di chi la riceve.",
  },
  {
    id: 9,
    q:
      'Come richiedo il  materiale operativo (etichette, packing list, buste a3, buste a4, etc..)?',
    a:
      'Può effettuare la richiesta direttamente dalla sua area riservata al link mediterraneaexpress.com dalla sezione Support Ticket. Un nostro operatore risponderà in tempi brevi, inviandole il materiale richiesto.',
  },
]

const Faq = ({ question }) => {
  const [open, isOpen] = useState(false)

  const handleOpen = (e) => {
    e.preventDefault()
    isOpen(!open)
  }

  return (
    <dl className="mb-4 text-sm">
      <dt>
        <button
          onClick={handleOpen}
          className="text-left font-semibold hover:text-gls-blue-600 focus:outline-none"
        >
          <div className="flex items-center">
            {!!open ? (
              <FaMinus className="inline-block text-gls-yellow mr-2" />
            ) : (
              <FaPlus className="inline-block text-gls-yellow mr-2" />
            )}
            <span className="flex-1">{question.q}</span>
          </div>
        </button>
      </dt>
      {!!open && (
        <dl className="pl-6 mt-3 mb-8 border-l-4 border-blue-200 text-gray-700">
          {question.a}
        </dl>
      )}
    </dl>
  )
}

const filterFaq = (keyword) => (faq) => {
  return (
    faq.q.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
    faq.a.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
  )
}

const Faqs = () => {
  const [keyword, setKeyword] = useState('')

  const handleChangeKeyword = (e) => {
    setKeyword(e.target.value)
  }

  return (
    <Layout>
      <SEO
        title="FAQ"
        description="Domande frequenti riguardo ai servizi offerti da Mediterranea Express - Corriere GLS Sicilia"
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          Frequently asked questions
        </h1>
      </section>
      <section className="py-12 px-12 bg-white lg:rounded-lg shadow-xl">
        <div className="form-group relative mb-12 w-64">
          <label id="keywordLabel" htmlFor="keyword" className="sr-only">
            Cerca
          </label>
          <input
            id="keyword"
            name="keyword"
            aria-labelledby="keywordLabel"
            type="text"
            className="form-input py-2 pl-4 pr-10 w-full rounded-full bg-light-blue-gray"
            placeholder="Cerca..."
            value={keyword}
            onChange={handleChangeKeyword}
          />
          <FaSearch className="absolute top-0 right-0 mt-3 mr-4 text-gls-blue-700" />
        </div>

        {FAQS.filter(filterFaq(keyword)).map((faq) => {
          return <Faq key={faq.id} question={faq} />
        })}
      </section>
    </Layout>
  )
}

export default Faqs
